<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6" class="page-header">
        <h1 class="page-title">Discounts</h1>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="6" md="6" sm="6">
        <v-btn color="primary" @click="$router.push({ name: 'website.discount.code.create' })">+ Create discount</v-btn>
      </v-col>
      <v-col class="px-3 py-3">
        <Notification v-if="error" :messages="error" type="error" />

        <v-row class="elevation-1 elevation-form page__table-container">
          <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />

          <v-col cols="12">
            <Resource
              :document="document"
              :filter-fields="null"
              :filter-data="filterData"
              :filter-callback="setFilterData"
              :searchable="searchableField"
              :columns="columns"
              :update-router-callback="getUpdateRouterData"
              :callback-action="getAction"
              :actionItems="items"
              :routeName="updateRouteName"
            />
            <!-- <pagination :page.sync="page"></pagination> -->
            <v-col cols="12" class="d-flex justify-space-between">
              <limit-page :callback="setLimit" />
              <pagination
                :visible="0"
                v-if="!$parent.isLoading && document"
                :length="lengthPage"
                :page.sync="page"
              ></pagination>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <delete-action :content="ids.length + ' discounts?'" title="Delete discounts" />
  </div>
</template>

<script>
import { discountService } from '@/apis/discount';
import datetime from '@/helpers/datetime';
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      showDialogDelete: false,
      ids: [],
      error: null,
      isLoading: false,
      datetime,
      items: [{ title: 'Delete discounts', action: 'delete' }],
      tabs: [
        { name: 'All', key: '' },
        { name: 'Active', key: 'active' },
        { name: 'Scheduled', key: 'scheduled' },
        { name: 'Expired', key: 'expired' },
      ],
      resourceType: 'discount',
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        Code: {
          field: 'discount_code',
          kind: 'view',
        },
        Status: {
          field: 'discount_status',
          kind: 'status',
        },
        Start: {
          field: 'discount_start_date',
          kind: 'date',
          type: 'utc',
        },
        End: {
          field: 'discount_end_date',
          kind: 'date',
          type: 'utc',
        },
      },
      updateRouteName: 'website.discount.code.update',
      searchableField: 'title_search',
      sortable: [],
    };
  },
  created() {},
  methods: {
    async submitDelete() {
      try {
        let params = {
          ids: this.ids,
        };
        let data = await discountService.deleteByIds(this.ids);

        await this.getResources();
      } catch (error) {}
      this.showDialogDelete = false;
    },
    getAction(action, ids) {
      if (action.action == 'delete') {
        this.showDialogDelete = true;
        this.ids = ids;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
